import React, { Component } from "react";
import Page from "../components/Page";
import DownloadButton from "../components/DownloadButton/DownloadButton";
import Gallery from "react-photo-gallery";
import Lightbox from "react-images";
import API from "../API";

class Job extends Component {
    constructor() {
        super();
        this.state = {
            width: -1,
            photosSmall: [],
            photosLarge: [],
            photosOriginal: [],
            currentImage: 0,
            job: "",
            loading: true,
        };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.downloadImage = this.downloadImage.bind(this);
        this.downloadAllImages = this.downloadAllImages.bind(this);
    }

    componentDidMount() {
        var jobName = this.props.match.params.name;
        this.setState({ job: jobName, loading: true });
        fetch(API.listJobURL(jobName))
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error.indexOf("not_found") > 0 ? "Er bestaat geen job met deze naam" : result.error,
                        loading: false,
                    });
                    return;
                }

                var photosSmall = [];
                var photosLarge = [];
                var photosOriginal = [];

                for (let index = 0; index < result.images.length; index++) {
                    var photo = result.images[index];

                    photosSmall.push({
                        src: photo["smallURL"],
                        width: photo["width"],
                        height: photo["height"],
                        title: photo["alt"],
                    });

                    photosLarge.push({
                        src: photo["largeURL"],
                        width: photo["width"],
                        height: photo["height"],
                        title: photo["alt"],
                    });

                    photosOriginal.push(photo["downloadURL"]);
                }
                this.setState({
                    photosSmall: photosSmall,
                    photosLarge: photosLarge,
                    photosOriginal: photosOriginal,
                    job: result.name,
                    downloadURL: result.downloadURL,
                    loading: false,
                });
            })
            .catch((error) => {});
    }

    openLightbox(event, obj) {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }

    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    downloadImage() {
        var image = this.state.photosOriginal[this.state.currentImage];
        window.location.href = image;
        return false;
    }

    downloadAllImages() {
        window.location.href = this.state.downloadURL;
        return false;
    }

    render() {
        var title = this.state.job;

        var subtitle = "";
        if (this.state.photosSmall.length === 1) {
            subtitle = this.state.photosSmall.length + " foto";
        } else if (this.state.photosSmall.length > 1) {
            subtitle = this.state.photosSmall.length + " foto's";
        }

        if (this.state.loading) {
            return (
                <Page shortTitle={title} title={title} width="" margin="22px">
                    <p>Bezig met laden…</p>
                </Page>
            );
        } else if (this.state.error) {
            return (
                <Page shortTitle={title} title={title} width="" margin="22px">
                    <p>{this.state.error}</p>
                </Page>
            );
        } else {
            return (
                <Page shortTitle={title} title={title} subtitle={subtitle} width="" margin="22px">
                    <DownloadButton key="downloadButton" title="Download Alles" handler={this.downloadAllImages} />

                    <Gallery photos={this.state.photosSmall} columns={6} margin={4} onClick={this.openLightbox} />
                    <Lightbox
                        theme={{ container: { background: "rgba(0, 0, 0, 0.85)" } }}
                        images={this.state.photosLarge.map((x) => ({
                            ...x,
                            srcset: x.srcSet,
                            caption: x.title,
                            alert: x.title,
                        }))}
                        backdropClosesModal={true}
                        onClose={this.closeLightbox}
                        onClickPrev={this.gotoPrevious}
                        onClickNext={this.gotoNext}
                        currentImage={this.state.currentImage}
                        isOpen={this.state.lightboxIsOpen}
                        customControls={[<DownloadButton key="downloadButton" title="Download" handler={this.downloadImage} />]}
                        width={1600}
                    />
                </Page>
            );
        }
    }
}

export default Job;
