import React, { Component } from "react";
import HeaderLink from "./HeaderLink";
import { css } from "glamor";

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = { tocIsHidden: true };
    this.toggleToc = this.toggleToc.bind(this);
    this.showHome = this.showHome.bind(this);
  }

  toggleToc() {
    const { tocIsHidden } = this.state;
    this.setState({
      tocIsHidden: !tocIsHidden
    });
  }

  showHome() {
    window.location.href = "/";
  }

  render() {
    let headerCss = css(
      {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "80px",
        backgroundColor: "rgba(32, 32, 32, 0.85)",
        zIndex: 888,
        backgroundImage:
          "url(" + process.env.PUBLIC_URL + "/images/logo_wit_285x192.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "20px 50%",
        backgroundSize: "96px auto",
        cursor: "pointer",
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)"
      },
      {
        "@media(max-width: 640px)": {
          height: "44px",
          backgroundSize: "50px auto"
        }
      }
    );

    let headerH1Css = css(
      {
        position: "absolute",
        top: 0,
        left: "130px",
        height: "80px",
        lineHeight: "98px",
        margin: 0,
        padding: 0,
        fontSize: "1.4em"
      },
      {
        "@media(max-width: 640px)": {
          lineHeight: "48px",
          left: "80px"
        }
      }
    );

    let iconTocCss = css(
      {
        position: "absolute",
        top: "0",
        right: "10px",
        margin: "0px",
        padding: "0px",
        paddingRight: "10px",
        cursor: "pointer",
        backgroundImage: this.state.tocIsHidden
          ? "url(" + process.env.PUBLIC_URL + "/images/icon_toc.png)"
          : "url(" + process.env.PUBLIC_URL + "/images/icon_close.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "44px 44px",
        height: "80px",
        width: "44px",
        display: "none",
        zIndex: 1111
      },
      {
        "@media(max-width: 900px)": {
          display: "inline"
        }
      },
      {
        "@media(max-width: 640px)": {
          height: "44px",
          width: "44px",
          paddingRight: 0
        }
      }
    );

    let headerUlCss = css(
      {
        position: "absolute",
        top: 0,
        right: 0,
        margin: 0,
        padding: 0,
        paddingRight: "10px",
        overflow: "hidden"
      },
      {
        "@media(max-width: 900px)": {
          display: this.state.tocIsHidden ? "none" : "inline",
          position: "fixed",
          width: "100vw",
          height: "100vh",
          left: 0,
          top: "80px",
          zIndex: 999,
          backgroundColor: "rgba(32, 32, 32, 1)",
          borderTop: "1px solid #ccc",
          overflow: "auto"
        }
      },
      {
        "@media(max-width: 640px)": {
          top: "44px"
        }
      }
    );

    return (
      <header className={headerCss}>
        <h1 className={headerH1Css} onClick={this.showHome}>{this.props.title}</h1>
        {/* <p className={iconTocCss} onClick={this.toggleToc} />
        <nav>
          <ul className={headerUlCss}>
            <HeaderLink action={this.toggleToc} url="/" label="Home" />
            <HeaderLink
              action={this.toggleToc}
              url="/portfolio"
              label="Portfolio"
            />
            <HeaderLink action={this.toggleToc} url="/vragen" label="Vragen?" />
            <HeaderLink
              action={this.toggleToc}
              url="/prijzen"
              label="Prijzen"
            />
            <HeaderLink
              action={this.toggleToc}
              url="/over-mezelf"
              label="Over Mezelf"
            />
            <HeaderLink
              action={this.toggleToc}
              url="/contact"
              label="Contacteer Ons"
            />
          </ul>
        </nav> */}
      </header>
    );
  }
}

export default Header;
