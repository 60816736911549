import React, { Component } from "react";
import Page from "../components/Page";
import ReactGA from "react-ga";
import API from "../API";
import { css } from "glamor";

class Home extends Component {
    render() {
        css.global("header, footer", {
            "display": 'none',
        });

        css.global(".wrapper", {
            "margin": "0 auto !important",
        });

        return (
            <Page title="" shortTitle="">
                <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ width: "640px", maxWidth: "95%" }}>
                        <p>In september 2023 hield Jono Fotografie op met bestaan.</p>
                        <p>We willen alle klanten graag bedanken voor het vertrouwen die jullie ons de 9 jaren van ons bestaan gegeven hebben.</p>
                        <p>Wie weet komen we elkaar wel in een ander avontuur terug tegen :)</p>
                        <p></p>
                        <p>Pieter &amp; Fiona</p>
                    </div>
                </div>
            </Page>
        );
    }
}

export default Home;
