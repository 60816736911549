import React, { Component } from "react";
import { css } from "glamor";

class Footer extends Component {
  render() {
    let footerCss = css(
      {
        boxShadow: "0px -4px 8px 0px rgba(0, 0, 0, 0.25)",
        textAlign: "center",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "44px",
        backgroundColor: "rgba(32, 32, 32, 0.85)",
        zIndex: 888,
        fontSize: "1.2em",
        color: "gray"
      },
      {
        "@media(max-width: 900px)": {
          display: "none"
        }
      }
    );

    let footerParagraphCss = css({
      margin: "0px",
      padding: "0px",
      lineHeight: "44px",
      fontSize: "0.8em",
      fontWeight: "normal"
    });

    let currentYear = new Date().getFullYear();

    return (
      <footer className={footerCss}>
        <p className={footerParagraphCss}>
          Jono Fotografie • Mouterijstraat 3, 8730 Sint-Joris •
          <nobr>0497 440 481</nobr> • Copyright 2014-{currentYear} Jono
          Fotografie
        </p>
      </footer>
    );
  }
}

export default Footer;
