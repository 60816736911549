import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import * as Constants from "../Constants";
import Main from "./Main";
import { css } from "glamor";

class App extends Component {
  render() {
    css.global(".shadow", {
      boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)"
    });

    return (
      <div>
        <Header title={Constants.siteName} />
        <Main />
        <Footer />
      </div>
    );
  }
}

export default App;
