import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import * as Constants from "../Constants";
import { css } from "glamor";

class Page extends Component {
    render() {
        var title = this.props.title;
        var subtitle = this.props.subtitle;

        var shortTitle = this.props.shortTitle;
        if (!shortTitle) {
            shortTitle = title;
        }
        if (shortTitle) {
            shortTitle += " | ";
        }
        shortTitle += Constants.siteName;

        var width = this.props.width !== undefined ? this.props.width : "700px";
        var margin = this.props.margin !== undefined ? this.props.margin : "auto";

        let h1Css = css({
            paddingBottom: 0,
            marginBottom: 8,
            display: "inline-block",
        });

        let h2Css = css({
            paddingTop: 0,
            marginTop: 0,
            marginBottom: 20,
            marginLeft: 10,
            display: "inline-block",
        });

        return (
            <DocumentTitle title={shortTitle}>
                <div
                    className="wrapper"
                    style={{
                        maxWidth: width,
                        marginLeft: margin,
                        marginRight: margin,
                    }}
                >
                    <div>
                      {title && <h1 className={h1Css}>{title}</h1>}
                      {subtitle && <h2 className={h2Css}>{subtitle}</h2>}
                    </div>
                    {this.props.children}
                </div>
            </DocumentTitle>
        );
    }
}

export default Page;
