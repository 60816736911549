import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Page from "./Page";
import Home from "../views/Home";
import Job from "../views/Job";

const NoMatch = ({ location }) => (
  <Page title="Oeps, niet gevonden">
    <p>We konden niets vinden voor deze URL:</p>
    <p><code>{location.pathname}</code></p>
    <p><a href="/">Ga terug naar de homepage</a></p>
  </Page>
);

const Main = () => (
  <main>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/job/:name" component={Job} />
      <Route component={NoMatch} />
    </Switch>
  </main>
);

export default Main;
