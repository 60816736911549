import React, { Component } from "react";
import DownloadIcon from "./icon";
import { css } from "glamor";

class DownloadButton extends Component {
  render() {
    let textCss = css({
      padding: "10px 0",
      lineHeight: "20px",
      display: "inline-block",
      textDecoration: "none",
      cursor: "pointer",
      left: -15,
      position: "relative"
    });

    return (
      <div>
        <button
          title="Download"
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            float: "left",
            height: 40,
            outline: "none",
            padding: 10,
            position: "relative",
            left: -10,
            top: 0,
            verticalAlign: "bottom",
            width: 40
          }}
          onClick={this.props.handler}
        >
          <span dangerouslySetInnerHTML={{ __html: DownloadIcon }} />
        </button>
        <a onClick={this.props.handler} className={textCss} href="#download">
          {this.props.title}
        </a>
      </div>
    );
  }
}

export default DownloadButton;
