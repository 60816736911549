class API {

    constructor() {
        this.listJobURL = this.listJobURL.bind(this);
        this.url = this.url.bind(this);
        this.rootURL = this.rootURL.bind(this);
    }

    static listJobURL(jobName, reverse) {
        var path = '/job/' + encodeURI(jobName) + '/list';
        if (reverse) {
            path = path + "?reverse=1";
        }
        return this.url(path);
    }

    static url(subpath) {
        return this.rootURL() + subpath;
    }

    static rootURL() {
        if (process.env.API_URL) {
            return process.env.API_URL;
        }
        return "https://jonoapi.yellowduck.be";
    }

}

export default API;
